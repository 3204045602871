// All JavaScript in here will be loaded server-side
import React from 'react';

import DOMPurify from 'dompurify';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';

// Expose components globally so ReactJS.NET can use them
globalThis.React = React;
globalThis.ReactDOM = ReactDOM;
globalThis.ReactDOMServer = ReactDOMServer;
globalThis.DOMPurify = DOMPurify;
